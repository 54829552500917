require('../scss/index.scss');
import AOS from 'aos';
import 'aos/dist/aos.css'; 

// import Swiper JS
import Swiper, { Autoplay, Pagination, Navigation, Mousewheel } from 'swiper';
Swiper.use([Autoplay, Pagination, Navigation, Mousewheel]);
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';


// import node marquee
import nodeMarquee from 'node-marquee';

// jquery
var $ = require( "jquery" );
import { css } from 'jquery';

// SELECT2
import 'select2';
import 'select2/dist/css/select2.css';



// .................................................................................
// SWIPERS
// .................................................................................

var swiper_testimonials = new Swiper('#swiper-testimonials', {
    slidesPerView: "auto",
    loop: true,

    speed: 1500,

    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },

});

var swiper_expect = new Swiper('#swiper-expect-mobile', {
    slidesPerView: "auto",
    slideToClickedSlide: true,
});

var swiper_sessions = new Swiper('#swiper-featured-sessions', {
    slidesPerView: "auto",
    slideToClickedSlide: true,
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
});

// var swiper_theshed = new Swiper('#swiper-the-shed', {
//     slidesPerView: "auto",
//     // spaceBetween: 25,
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: true,
//         type: 'bullets',
//     },
//     slideToClickedSlide: true,
//     autoHeight: true
// });

var swiper_map = new Swiper('#swiper-map', {
    slidesPerView: "auto",
    pagination: {
        el: ".swiper-pagination-map",
        clickable: true,
        type: 'bullets',
    },
    breakpoints: {
        320: {
            speed: 700,
        },
        1024: {
            speed: 0,
        }
    },

    slideToClickedSlide: true,
});

var swiper_speakers_nolink = new Swiper('#swiper-speakers-nolink', {
    slidesPerView: "auto",
    // spaceBetween: 20,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: 'bullets',
    },
    slideToClickedSlide: true,
    mousewheel: true,
});



// .................................................................................
// HAMBRUGER
// .................................................................................

function hamburgerEvents() {

    const hamburgers = $("#hamburger, #hamburger-m");
    const menupage = $("#menupage");
    const mobilebar = $("#mobilebar");
    const mobileregister = $('#mobileregister');
    
    hamburgers.on('click',()=> {

        // hide menu
        if (menupage.hasClass('expanded')) {
            menupage.removeClass("expanded");
            hamburgers.removeClass("expanded");
            mobilebar.removeClass("expanded");
            mobileregister.removeClass("expanded");
            
            $('html, body').css('overflow', 'initial');

        // display menu
        } else {
            menupage.addClass("expanded");
            hamburgers.addClass("expanded");
            mobilebar.addClass("expanded");
            mobileregister.addClass("expanded");
            
            if (tablet) {
                // we delay this in order to not see the page jump on click
                setTimeout(()=>{
                    $('html, body').css('overflow', 'hidden');
                },600); // menumobile opacity transition duration
            }
        }
    });

    menupage.find('a').on('click', function (){
        menupage.removeClass("expanded");
        hamburgers.removeClass("expanded");
        mobilebar.removeClass("expanded");
        mobileregister.removeClass("expanded");
    });

}
hamburgerEvents();
function checkMenuTablet() {

    const menupage = $("#menupage");

    if (tablet && menupage.hasClass('expanded')) {
        // we delay this in order to not see the page jump on click
        setTimeout(()=>{
            $('html, body').css('overflow', 'hidden');
        },600); // menumobile opacity transition duration
    } else {
        $('html, body').css('overflow', 'initial');
    }
}


// .................................................................................
// MENU ACCORDION
// .................................................................................

function menuAccordionPrepare() {
const all_loaders = $('.js-menuaccordion-loader');

all_loaders.each(function () {
    const loader = $(this);
    const content = loader.next();

    content.css('overflow','hidden');
    content.css('cursor','pointer');
    closeAccordion(loader, content);
});

all_loaders.on('click', function(){
    const loader = $(this);
    const content = loader.next();

    if (content.hasClass('open')) {
    closeAccordion(loader, content);
    } else {
    loader.parent().parent().find('.js-menuaccordion-loader').each(function () {
        const canidate = $(this);
        const canidate_content = canidate.next();
        closeAccordion(canidate, canidate_content);
    });
    openAccordion(loader, content);
    }
})

function closeAccordion(loader, content) {
    content.css('transition','max-height 0.4s ease-out');
    content.css('max-height','0px');
    content.removeClass('open');
    loader.removeClass('open');
}
function openAccordion(loader, content) {
    content.css('transition','max-height 0.4s ease-in');
    content.css('max-height', loader.data('height'));
    content.addClass('open');
    loader.addClass('open');
}

};
menuAccordionPrepare();


// .................................................................................
// HOME - EXPECT CARDS SLIDERS
// .................................................................................

// desktop

$('.js-expect-link-desktop').on('mouseenter', function() {
    homeExpectCardLoad($(this));
});

function homeExpectCardLoad(link, mode = '') {

    let id = link.data('id');
    let postcard = $(`#${id}`);

    const links = link.siblings();
    const postcards = postcard.siblings();


    links.removeClass('active');
    link.addClass('active');
    postcards.removeClass('active');  
    postcard.addClass('active');  

    stopVideos(postcards);
    loadVideo(postcard);

    // set height in mobile
    if (tablet) {
        const height = postcard.outerHeight();
        postcard.parent().css('min-height', `${height}px`);
    }
}


// mobile version
$('.js-expect-link-mobile').on('mouseenter', function() {
    homeExpectCardLoad($(this));
});

// equal heights in mobile version
function homeMobileExpectCardsEven() {
    const postcards = $('.template-home .s3m__cards__item');
    const mH = maxHeight(postcards);
    postcards.first().parent().css('min-height', `${mH}px`);
}


// when visible activate first slide
function homePrepareFirstExpect(){

    const expectAutoloadDesktop = $('.js-expect-autoload-desktop');
    if (expectAutoloadDesktop[0]) {
        if (isVisible(expectAutoloadDesktop)) {
            expectAutoloadDesktop.removeClass('js-expect-autoload-desktop');
            homeExpectCardLoad(expectAutoloadDesktop,'auto');
        }
    }
    
    const expectAutoloadMobile = $('.js-expect-autoload-mobile');
    if (expectAutoloadMobile[0]) {
        if (isVisible(expectAutoloadMobile)) {
            expectAutoloadMobile.removeClass('js-expect-autoload-mobile');
            setTimeout(() => {
                homeExpectCardLoad(expectAutoloadMobile,'auto');
            }, 1000);
        }
    }
}


// auxiliary functions
// -------------------
function loadVideo(container) {

    // locate video
    const video = container.find("video");

    // load if unloaded
    if (video.hasClass('unloaded')) {

        var video_src = video.data('src');
        video.find('source').attr('src', video_src);

        setTimeout(function () {
            video[0].load();
            video[0].play();
            video.css('opacity', '1');
        }, 500);

        setTimeout(function () {
            video.removeClass('unloaded');
        }, 100);

    // play if loaded
    } else {
        video[0].play();
        video.css('opacity', '1');
    }
}

function stopVideos(postcards) {

    postcards.each(function () {
        const video = $(this).find("video");

        if (!video.hasClass('unloaded')) {
            video.css('opacity', '0');
            video[0].pause();
            // video[0].currentTime = 0;    
        }
    });
}


// .................................................................................
// HOME - VIMEO POPUP
// .................................................................................    


$('.js-vimeo-loader').on('click', function() {

    const url = $(this).data('vimeo');
    const vimeo_id = getVimeoId(url);
    const iframe = $(`<iframe src="//player.vimeo.com/video/${vimeo_id}?autoplay=1&color=FF5300&title=0&byline=0&portrait=0" 
                                 frameborder="0" 
                                 webkitallowfullscreen mozallowfullscreen allowfullscreen
                                 allow="autoplay; fullscreen; picture-in-picture; encrypted-media" 
                                 scrolling="no">
                         </iframe>`);


    let popup = $(`<div id="popup" class="vimeo-popup"><div class="content"><div id="popup-close">╳</div></div></div>`);
    $( 'body' ).append(popup);
    popup.find('.content').append(iframe);

    var player = new Vimeo.Player(iframe[0]);

    // entering animation
    setTimeout(()=>{
        popup.css('max-height','100%');
        player.play();
    }, 500);

    // closing event listener
    popup.on('click', function(event) { 
        const close = popup.find('#popup-close');
        if (event.target !== iframe[0] || event.target == close) popup.remove();
    });

});



// // .................................................................................
// // HOME - Logos Isotope
// // .................................................................................    

// function prepareHomeLogosIsotope() {

//     var iso_homelogos = new Isotope( document.getElementById('isotope-logos') , {
//         itemSelector: '.isotope-item',
//         layoutMode: 'fitRows',
//         filter: '.sponsor',
//         stagger: 20,
//         transitionDuration: '1s',
//         hiddenStyle: {
//             opacity: 0
//           },
//           visibleStyle: {
//             opacity: 1
//           }
//     });  


//     const $selectors = $('.js-isotope-selector');

//     $selectors.css('cursor','pointer');
    
//     $selectors.on('click',function() {
//         const $selector = $(this);

//         iso_homelogos.arrange({
//             filter: $selector.data('filter'),
//           });

//         $selector.siblings().removeClass('active');
//         $selector.addClass('active');
//     });
// }



// .................................................................................
//  WIDGETS
// .................................................................................    

$('.js-widget-loader').on('click', function() {

    const modal = $($(this).data('widget'));
    const close = modal.find('#popup-close');
    
    // display
    modal.css('display','block');

    // entering animation
    setTimeout(()=>{
        modal.css('max-height','100%');
        close.css('animation-name','appear');
    }, 100);

    // closing event listener
    if (modal.hasClass('first')) {

        modal.removeClass('first');

        modal.on('click', function(event) { 
            if (event.target == modal[0] || event.target == close[0]) {
                modal.css('max-height','0');
                modal.css('display','none');
            }
        });
    }
    
});



// .................................................................................
// FAQ
// .................................................................................    

// answer display
$('.js-faq-loader').on('click', function() {
    $(this).toggleClass('collapsed');
    setTimeout(()=>{
        $(this).toggleClass('expanded');
    },100);
});

// menu left
$('.js-faq-selector').on('click', function() {

    const clicked_selector = $(this);

    // reset menu highlighted item
    $('.js-faq-selector').removeClass('active');

    // highlight currrent item
    clicked_selector.addClass('active'); 

    // scroll to data target element
    scrollToData(clicked_selector);

});

// boxes
$('.js-faq-box').on('click', function() {

    const clicked_box = $(this);

    // reset menu highlighted item
    $('.js-faq-selector').removeClass('active');

    // highlight currrent item
    const target = $(`#${clicked_box.data('id')}`);
    target.addClass('active');

    // scroll to data target element
    scrollToData(clicked_box);

});


// .................................................................................
// SPEAKERS - loadMoreSpeakers
// .................................................................................    


// Home
// $('.template-home .grid-speakers__card').attr('data-aos','fade-right');

// SPEAKERS

function loadMoreSpeakers(selector) {

    let targets = $(`${selector}.unloaded`);

    for (let i = 0; (i < 12) && (i < targets.length) ; i++) {
        const target = targets.eq(i);

        target.removeClass('unloaded');
        target.removeAttr('data-aos');
        target.removeAttr('data-aos-delay');

        setTimeout(()=>{
            target.attr('data-aos','fade');

            setTimeout(()=>{
                target.css('opacity','1');
            },100);
        },100);
    }
}



// .................................................................................
// SVG MAPs
// .................................................................................


if ($('.template-page-experience, .template-page-ple')[0]) {

    const $wiper = $('#swiper-map');
    const infos = $('[data-map]');
    const layers = $('svg .map-layer');

    const alphabet = 'ABCDEFG';

    for (let i = 0; i < alphabet.length; i++) {

        let char = alphabet[i];
        let current_layer = $(`svg #map_${char}`);
        let current_info = $(`[data-map="map_${char}"]`);

        current_layer.on('mousemove click', selectLayer);
        current_info.on('click', selectLayer);

        function selectLayer() {

            if (!current_info.hasClass('active')) {

                infos.removeClass('active');
                layers.removeClass('active');
    
                current_layer.addClass('active');
                current_info.addClass('active');
    
                swiper_map.slideTo(i);
                $wiper.find('.swiper-slide').children(i).addClass('active');
    
                SVGMap_loaded = true;
            }
        }
    } 

    // $('#edificio_web').on('mouseleave', function(e){
    //     layers.removeClass('active');
    //     infos.removeClass('active');
    // });

}

let SVGMap_loaded = false;
function SVGMapFirstLoad() {
    if (isVisible($('#edificio_web')) && !SVGMap_loaded) {
        setTimeout(function () {

            const first_info = $('#swiper-map').find('.swiper-slide').first();
            const first_layer = $('svg #map_A');
            const layers = $('svg .map-layer');

            first_info.css('transition', 'background-color 1s ease, opacity 1s ease');
            layers.css('transition', 'fill-opacity 1s ease');

            first_layer.addClass('active');
            first_info.addClass('active');
            layers.addClass('loaded');

            setTimeout(function () {
                first_info.css('transition', 'background-color 0.3s ease, opacity 0.3s ease');
                layers.css('transition', 'fill-opacity 0.3s ease');
            }, 1000);

            SVGMap_loaded = true;
        }, 800);
    }
}


// .................................................................................
// PAGE - Experience at The Shed - VIDEOS LOAD ON SCROLL
// .................................................................................

function checkExperienceVideos() {
    $('.js-experience-video-loader').each(function () {
        let container = $(this);
        if (isVisible(container)) {
            loadVideo(container);
            container.removeClass('js-experience-video-loader');
        }
    });
}

// .................................................................................
// PAGE - Sponsors & Catalysts - CATALYST LOGOS interaction
// .................................................................................

if ($('.template-page-sponsors')[0]) {

    const $wrap = $('.s1__wrap');
    const $all = $('.js-catalyst');
    const $page = $('#catalysts-page');

    $('[data-catalyst]').on('click', function () {
        const $logo = $(this);
        const $target = $($logo.data('catalyst'));

        if ($wrap.hasClass('transformed')) {
            if ($target.hasClass('active')) {
                closeCatalyst();
                document.getElementById('catalysts-page').removeEventListener('click', closeCatalystPage);
            } else {
                changeCatalyst($target);
            }
        } else {
            openCatalyst($target);
            setTimeout(function () {
                document.getElementById('catalysts-page').addEventListener('click', closeCatalystPage);
            },500);
            
        }
    });

    function closeCatalyst() {
        $wrap.removeClass('transformed');
        $all.removeClass('active');
        $page.removeClass('active');
        minHeightCatalystsReset();
    }

    function openCatalyst($catalyst) {
        $wrap.addClass('transformed');
        $catalyst.addClass('active');
        $page.addClass('active'); 
        minHeightCatalysts($catalyst);
    }

    function changeCatalyst($catalyst) {
        $all.removeClass('active');
        $catalyst.addClass('active');
        minHeightCatalysts($catalyst);
    }

    function closeCatalystPage(event) {
        if (event.target != $('.js-catalyst.active')[0]) closeCatalyst();
    }

    // min height
    function minHeightCatalysts($catalyst) {
        const mH = maxHeight($catalyst);
        $wrap.css('min-height', `${mH + 80}px`);
    }
    function minHeightCatalystsReset() {
        $wrap.css('min-height', `${400}px`);
    }
}


// .................................................................................
// SESSIONS (POST-EVENT) - Featured
// .................................................................................

// when visible activate first session
function prepareFirstFeaturedSession(){

    const featuredContainer = $('#sessions-featured');

    if (featuredContainer[0] && featuredContainer.hasClass('unloaded')) {
        if (isVisible(featuredContainer)) {
            loadFeaturedSession(featuredContainer,'first');
            setTimeout(() => {
                featuredContainer.removeClass('unloaded');
            }, 2000);
        }
    }
}

function loadFeaturedSession(link, mode = '') {

    if (mode === 'first') {
        link = $('#swiper-featured-sessions .swiper-wrapper').children().first();
    } else {
        $('#sessions-featured').removeClass('unloaded');
    }

    let id = link.data('id');
    let postcard = $(`#${id}`);

    const links = link.siblings();
    const postcards = postcard.siblings();

    links.removeClass('active');
    link.addClass('active');
    postcards.removeClass('active');  
    postcard.addClass('active');  

    const height = postcard.outerHeight();
    postcard.parent().css('min-height', `${height}px`);
}

// equal heights
function evenHeightsFeaturedSessions() {
    const postcards = $('#sessions-featured .s1__postcard');
    const mH = maxHeight(postcards);
    postcards.first().parent().css('min-height', `${mH}px`);
}

// listener swipe change

swiper_sessions.on('slideChange', function (e) {
    let link = $($('#swiper-featured-sessions .swiper-wrapper').children()[swiper_sessions.activeIndex]);
    loadFeaturedSession(link);
});



// .................................................................................
// SESSIONS (POST-EVENT) - FORMS SELECT2
// .................................................................................

function selectFormPrepare() {

    const $form = $('#form-sessions-select');
    const $eventSelect = $('.js-select2');
    const $resetElement = $('.form-sessions-clear');

    if ($form[0]) {

        $eventSelect.each(function(){

            let element = $(this);
    
            element.select2({
                placeholder: element.data('placeholder'),
                multiple: true,
                // dropdownAutoWidth: 'true',
            });
    
        });
    
        // disable search
    
        $eventSelect.on('select2:opening select2:closing', function( event ) {
            var $searchfield = $(this).parent().find('.select2-search__field');
            $searchfield.prop('disabled', true);
        });
    
        // custom placeholder
    
        $('select').each(function () {
            updatePlaceholder($(this));
        }); 
    
        $('select').on('select2:close', function() {
            updatePlaceholder($(this));
        }); 

        function updatePlaceholder(element) {
            var uldiv = element.siblings('span.select2').find('ul');
            var count = element.select2('data').length - 1; // -1 so that we don't count the empty option
            const name = element.data('placeholder');
            if(count==0){
                uldiv.html(`<li>${name}</li>`);
            }
            else{
                uldiv.html(`<li>${name} (${count})</li>`);
            }
        }

        // close all dropdowns if we click elsewhere

        $('body').on('click', function (evt) {

            const target = evt.target;

            const elementContains = (parent, child) =>
                parent !== child && parent.contains(child);

            if (!elementContains($form[0], target)) {
                $eventSelect.each(function() {
                    $(this).select2('close');
                });
            }
        });

        // reset button

        $resetElement.on('click', () => {

            $eventSelect.each( function(i,el) {
                $(this).val(null).trigger('change');
                $(this).val('');
                updatePlaceholder($(this));
            });

            prepareSessionsForm();

        });

        // retrigger ajax in case of change of any select

        $('select').on('change', function() {
            formSessionsSearch();
        }); 
    }
}



// .................................................................................
// SESSIONS (POST-EVENT) - FORMS
// .................................................................................

// expand search
$('#form-search.closed').on('click', function(e) {
    e.preventDefault();
    $(this).removeClass('closed');
});

// prepare sessions from
function prepareSessionsForm() {
   
    // search
    function prepareSearch() {
        $('#form-search-submit').on('click', function(e) {
            if (!$('#form-search.closed')[0]) {
                e.stopImmediatePropagation();
                e.preventDefault();
                formSessionsSearch();
            }
        });
    }
    prepareSearch();

    // reset
    $('.form-sessions-clear').on('click', function(e) {
        $('#form-sessions-search')[0].reset();
    });
    
}

// perform search
let formSessionsDebounceTimer;

const debounce = (callback, time) => {
    window.clearTimeout(formSessionsDebounceTimer);
    formSessionsDebounceTimer = window.setTimeout(callback, time);
  };
   

function formSessionsSearch(page = 1) {

    debounce(function() {

        if (page == 1) formSessionsLoadMoreRemoveFeatured = false;
        formSessionsLoadMorePageCurrent = page;
    
        // console.log('search request:');
        // console.log($('#form-search-input').val());
        // console.log($('#form-select-topics').val());
        // console.log($('#form-select-formats').val());
        // console.log($('#form-select-locations').val());
        // console.log($('#form-select-days').val());
        // console.log(page);
        // console.log(formSessionsLoadMoreRemoveFeatured);
        // console.log($('input[name=csrfmiddlewaretoken]').val());
        // console.log('***');
    
        $.ajax({
            type: 'GET',
            url: $('#form-sessions-search').data('url'),
            data: {
                'text': $('#form-search-input').val(),
                'topics': $('#form-select-topics').val(),
                'formats': $('#form-select-formats').val(),
                'locations': $('#form-select-locations').val(),
                'days': $('#form-select-days').val(),
                'page': page,
                'unfeatured': formSessionsLoadMoreRemoveFeatured,
                'csrfmiddlewaretoken': $('input[name=csrfmiddlewaretoken]').val(),
            },
            success: function (data) {
                prepareResults(data);
            },
            error: function (e){
                console.log(e);
                $('#search-results').append('<div class="no-results container"><h3 class="h3">There was an error in your request.</h3></div>');
            },
            dataType: 'html'
        });
        
    },300);
    
}


// load more sessions
let formSessionsLoadMorePageCurrent = 1;
let formSessionsLoadMoreRemoveFeatured = true;

function fromSessionsloadMorePrep() { // run on window load

    fromSessionsloadMore();

    $('#loadmore-sessions').on("click", function(e) {
        e.preventDefault();
        formSessionsSearch(formSessionsLoadMorePageCurrent + 1)
    });

}
function fromSessionsloadMore(show_loader = true) {

    $('.sessionthumb__session:hidden').slice( 0 , 8 ).each(function () {
        showSlowly($(this));
    }); 

    if (show_loader) {
        $('#loadmore-sessions').show();
    } else {
        $('#loadmore-sessions').hide();
    }
}

// prepare results
function prepareResults(json) {

    const obj = JSON.parse(json);
    const dataobj = obj.dataobj
    const html = obj.html;

    setTimeout(function() {

        if (formSessionsLoadMorePageCurrent == 1) {
            $('#search-results').empty();
            $('#search-results').html(html);
        } else {
            $('#search-results').append(html);
        }

        // if results
        if ( !$('.no-results')[0] ) {
            
            // update possible selectable options
            // updateSelectable('#form-select-topics', dataobj.topics);
            // updateSelectable('#form-select-formats', dataobj.formats);
            // updateSelectable('#form-select-locations', dataobj.locations);
            // updateSelectable('#form-select-days', dataobj.days);

            // load more

            $( document ).ready(function() {
                fromSessionsloadMore(dataobj.more_pages == 'loadmore' ? true : false);
            });
        }

    },500);
}

// update a selector
function updateSelectable(selector, object_array) {
    
    const element = $(selector);

    console.log(object_array);

    $(document).ready(function() {
        element.select2({
            data: object_array
        })
        selectFormPrepare();
    });

}



// .................................................................................
// UTILS
// .................................................................................


// ------------------------------------------------------------
// LOADMORE
// - Builds a load more feature with button
// ------------------------------------------------------------

function loadMorePrep(loader, items, n = 2) { // run on window load

    $(items + ":hidden").slice( 0 , n ).each(function () {
        showSlowly($(this));
    }); 

    if ($(items + ":hidden").length != 0) {
        $(loader).show();
    }

    $(loader).on("click", function(e) {
        e.preventDefault();
        $(items + ":hidden").slice( 0 , n ).each(function () {
            showSlowly($(this));
        }); 
        if ($(items + ":hidden").length == 0) {
            $(loader).hide;
        }
    });
}



// ------------------------------------------------------------
// SHOW SLOWLY
// - Shows a block element with transition opacity
// ------------------------------------------------------------

function showSlowly(element) {
    element.css('display','block');
    setTimeout(()=>{
        element.css('opacity','1');
    },100);
}



// ------------------------------------------------------------
// MAX HEIGHT
// - Returns the maximum height of all elements of a selector
// ------------------------------------------------------------

function maxHeight(elements) {
    
    let max = 0;

    elements.each(function(){
        const el = $(this);

        el.css('height','fit-content');
        const height = el.outerHeight();
        if ( height > max )  max = height;

    });

    return max;
}

// ------------------------------------------------------------
// IS VISIBLE
// - Returns if an element is visible
// - If scroll parent is not window, specify it in 'data-parent'
// ------------------------------------------------------------

function isVisible(element) {

    if (element[0]) {
        
        let parent_data = element.data('parent');

        if (parent_data) {
            let parent = document.querySelector(parent_data);
            const { bottom, height, top } = element[0].getBoundingClientRect();
            const containerRect = parent.getBoundingClientRect();
        
            return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;

        } else {
            var top_of_element = element.offset().top;
            var bottom_of_element = element.offset().top + $(window).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            
            return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
        }

    } else {

        return false;
    }
}

// ------------------------------------------------------------
// GET VIMEO ID
// - gets the Vimeo ID from the URL
// - source: https://stackoverflow.com/questions/2916544/parsing-a-vimeo-id-using-javascript
// ------------------------------------------------------------

function getVimeoId( url ) {

    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.
    var match = /vimeo.*\/(\d+)/i.exec( url );
  
    // If the match isn't null (i.e. it matched)
    if ( match ) {
        // The grouped/matched digits from the regex
        return match[1];
    }
}


// ------------------------------------------------------------
// SCROLL TO
// - animate scroll to selector specified in "data-scrollto"
// ------------------------------------------------------------

// $('.js-scrollto').on('click', function() {
//     scrollToData($(this));
// });

function scrollToData(loader) {

    const target_sel = loader.data('scrollto');
    const limit_sel = loader.data('scrollto-limit');

    const target_h = $(target_sel).offset().top;
    const limit_h = $(limit_sel).offset().top - screenHeight/2;
    
    function get_min(a,b) {
        return a < b ? a : b;
    }

    $('body, html').animate({
        scrollTop: get_min(target_h, limit_h)
    },1000);
}


// ------------------------------------------------------------
// RESPONSIVE
// - calculate basic screen/resonsive variables to use in your code
// ------------------------------------------------------------

var screenWidth, screenHeight, mobile, tablet, mobileSmall;

function calcScreen() {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;

    if (screenWidth <= 990) tablet = true; else tablet = false; 
    if (screenWidth <= 767) mobile = true; else mobile = false; 
    if (screenWidth <= 375) mobileSmall = true; else mobileSmall = false;   
}


// ------------------------------------------------------------
// COOL LINKS
// - animation on link pressing to make smooth page change
// - Sets opacity 0 to elements that are not part of loader when clicking a link
// ------------------------------------------------------------

$('a').on('click', function(e) {

    const link = $(this);
    const url = link.attr('href');

    let blank;
    link.attr('target') ? blank = link.attr('target') : blank = '';

    if (
        url.startsWith('/') 
        && !url.startsWith('/speaker/') 
        && blank !== '_blank' 
        && !link.attr('onclick') 
        ) {

        $('.loader-element').css('transition','opacity 0.7s ease');
        $('.loader-element').css('opacity','0');

        e.preventDefault();
        setTimeout(() => {
            window.location.href = url;

            setTimeout(() => {
                $('.loader-element').css('opacity','1');
                $('.loader-element').css('transition','opacity 1s ease-in');
            }, 1000);
        }, 700);

        setTimeout(() => {
            $('.loader-element').css('opacity','1');
        }, 2500);
    }
});


// ----------------------------------------
// ANCHOR HASH SMOOTH
// - gets location hash and perfroms smooth scroll animation
// ----------------------------------------

let the_hash = location.hash;

function checkLocationHash() { // must be called on window load
    if (the_hash) {
        setTimeout(function() {        
            scroll({
                top: $(the_hash).offset().top,
                behavior: "smooth"
              });
        }, 1000);
    }
}

function smoothScrollTo(element) {

}

// ----------------------------------------
// AUTO SCROLL TO TOP
// - makes an auto scroll to top in certain pages
// ----------------------------------------

function checkAutoTopScroll() { // must be called on window load

    let autoPages = [
        '.template-widget-common'
    ]

    setTimeout(function() {     
        
        autoPages.forEach((selector) => {

            let element = $(selector);

            if (element[0]) {
                scroll({
                    top: 0,
                    behavior: "smooth"
                });
            }
        });
    }, 400);
}

// ----------------------------------------
// INCLUDE WEBP FOR BACKGROUND IMAGES
// - checks webp compatibility
// - if compatible leaves current url of bg images as fallback, but add url for webp version
// - 404 will display if there is now webp
// ----------------------------------------

function canUseWebp() {
    let useWebp = false;
    if (typeof document === 'object') {
      // Client side rendering
      const canvas = document.createElement('canvas');
  
      if (
        canvas.getContext &&
        canvas.getContext('2d') &&
        canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
      ) {
        useWebp = true;
      }
    }
    return useWebp;
};

function removeExtension(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

function getExtension(filename) {
    return filename.substring(filename.lastIndexOf(".") + 1);
}

function changeToWebP() {
    if (canUseWebp()) {

        $('*').each(function(){
            let el = $(this);
            let oldbg = el.css('background-image');
            if (oldbg != 'none' && oldbg.startsWith('url(')) {

                let oldurl = /^url\((['"]?)(.*)\1\)$/.exec(oldbg);
                oldurl = oldurl ? oldurl[2] : ""; 
                let oldext = getExtension(oldurl);

                if (oldext == 'jpg' || oldext == 'jpeg' || oldext == 'png' ) {
                    let url = `${removeExtension(oldurl)}.webp`;
                    el.css('background-image', `${oldbg}, url("${url}")`);
                }
            }
        });
    }
};

changeToWebP();

// ============================================================================
// ============================================================================
// ============================================================================
// WINDOW LOAD

setTimeout(()=>{
    if ($('.loader')[0]) {
        $('.loader').css('opacity','1');
        // $('.loader__text').css('animation-name','blink');
        document.querySelector('html, body').style.overflow = 'hidden';
    }
}, 500);


if (!$('.template-home')[0]) {
    $('header').removeAttr('data-aos');
}

$( document ).ready(function() {
    if ($(".template-post-event")[0]) {
        selectFormPrepare();
        loadMorePrep('#loadmore-gallery', '.s3__gallery__pic', 4);
        fromSessionsloadMorePrep();
    }
});

$(window).on('load', function() {

    // ============================================================================
    // run before visible




    // unstyle richtext
    // if ($('.unstyle')[0]) unstyleRichText('.unstyle')

    // node marquee
    nodeMarquee({ 
        parent: '#node-marquee',
        // pauseOnHover: true
    });
    nodeMarquee({ 
        parent: '#node-marquee2',
        // pauseOnHover: true
    });
    nodeMarquee({ 
        parent: '#node-marquee-header',
        // pauseOnHover: true
    });
    nodeMarquee({ 
        parent: '#node-marquee-tickets',
        // pauseOnHover: true
    });

    // aos

    AOS.init({
        once: true,
        duration: 1700,
    });


    setTimeout(()=>{

        // first scrolls
        checkLocationHash();
        checkAutoTopScroll();

        $('.loader-element-hidden').addClass('loader-element');
        $('.loader-element-hidden:not("footer")').removeClass('loader-element-hidden');
        setTimeout(()=>{
            $('.loader-element-hidden.footer').removeClass('loader-element-hidden');
        },2000);
        $('.loader').remove();
        document.querySelector('html, body').style.overflow = 'initial';

        // ============================================================================
        // run after visible

        homePrepareFirstExpect();
        prepareFirstFeaturedSession();
        prepareSessionsForm();

        loadMoreSpeakers('.template-speakers .grid-speakers__card');

        SVGMapFirstLoad();

        if ($('.template-page-experience')) checkExperienceVideos();

        // ============================================================================
        // WINDOW SCROLL

        var previousScroll = 0;

        $(window).on('scroll', function(){

            var scroll = $(this).scrollTop();
            // downscroll code
            if (scroll > previousScroll){
                if ( mobile ) $(' #mobileregister ').slideDown({
                        duration: "fast",
                        start: function () {
                            $(this).css({
                                display: "flex"
                            })
                        }
                    });

            // upscroll code
            } else {
                if ( mobile ) $(' #mobileregister ').slideUp('fast');
            }
            previousScroll = scroll;

            homePrepareFirstExpect();
            prepareFirstFeaturedSession();

            if (isVisible($('#loadmoreSpeakers'))) {
                loadMoreSpeakers('.grid-speakers__card');
            }

            SVGMapFirstLoad();

            if ($('.template-page-experience')) checkExperienceVideos();

        });

        // ============================================================================
        // WINDOW RESIZE

        // resize execution
        function calc() {
            calcScreen();
            if (tablet) homeMobileExpectCardsEven();
            evenHeightsFeaturedSessions();
            // if (!mobile) $(' #mobileregister ').css('display','none');                
            if ( !mobile ) $(' #mobileregister ').slideUp('fast');

        }

        calc();

        $(window).on('resize', function(){
            calc();
            checkMenuTablet();

            SVGMapFirstLoad();
        });

    }, 400);

});